import { render, staticRenderFns } from "./NoResult.vue?vue&type=template&id=00771dd2&scoped=true&"
import script from "./NoResult.vue?vue&type=script&lang=js&"
export * from "./NoResult.vue?vue&type=script&lang=js&"
import style0 from "./NoResult.vue?vue&type=style&index=0&id=00771dd2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../infinity/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00771dd2",
  null
  
)

export default component.exports